import { defineAsyncComponent } from 'vue';

export const CloseDialogFooter = defineAsyncComponent(
  () => import('@/components/custom/Modals/components/CloseDialogFooter.vue')
);

export const DialogFooter = defineAsyncComponent(
  () => import('@/components/custom/Modals/components/DialogFooter.vue')
);

export const FilteredUsersInformation = defineAsyncComponent(
  () =>
    import('@/components/custom/Modals/components/FilteredUsersInformation.vue')
);

export const HeadingWithBadge = defineAsyncComponent(
  () => import('@/components/custom/Modals/components/HeadingWithBadge.vue')
);

export const SelectedUsers = defineAsyncComponent(
  () => import('@/components/custom/Modals/components/SelectedUsers.vue')
);

export const ValidationErrorAlert = defineAsyncComponent(
  () => import('@/components/custom/Modals/components/ValidationErrorAlert.vue')
);

export const ValidationArrayBlock = defineAsyncComponent(
  () => import('@/components/custom/Modals/components/ValidationArrayBlock.vue')
);
